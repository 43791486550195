<template>
  <div class="uploadFile-modal p-1">

    <!--    <CModal-->
    <!--        title="Upload Files"-->
    <!--        centered-->
    <!--        :show.sync="modal"-->
    <!--        :fade="false"-->
    <!--        addContentClasses="modal-wrap-content"-->
    <!--    >-->
    <!--      <CRow class="import-file" @drop.prevent="addFile" @dragover.prevent>-->
    <!--        <CCol class="mb-3">-->
    <!--          <span class="import-file-subtitle">Upload File or Folder</span>-->
    <!--        </CCol>-->

    <!--        <CCol class="card">-->
    <!--        <strong class="dark-grey mb-2">Browse your computer or drag and drop</strong>-->
    <!--          <div class="actions-wrapper input-group">-->
    <!--            <div class="actions custom-file">-->
    <!--              <CCol class="browse-field">-->
    <!--                <input-->
    <!--                    type="file"-->
    <!--                    multiple-->
    <!--                    id="uploadFieldName"-->
    <!--                    name="uploadFieldName"-->
    <!--                    @change="parse($event)"-->
    <!--                    class="custom-file-input"-->

    <!--                />-->
    <!--                <label class="custom-file-label" for="uploadFieldName"><p class="file-name">{{ fileName }}</p></label>-->
    <!--              </CCol>-->
    <!--            </div>-->
    <!--          </div>-->

    <!--        <div class="mt-3 ml-1">-->
    <!--        <CInputCheckbox id="replace-files" class=""></CInputCheckbox>-->
    <!--        <label class="form-check-label ml-3" for="replace-files">Replace files on existing file names with the same extension</label>-->
    <!--        </div>-->

    <!--      </CCol>-->
    <!--      </CRow>-->
    <!--    <div slot="footer">-->
    <!--      <CButton  @click="closeModal" color="secondary ml-2">-->
    <!--        Cancel-->
    <!--      </CButton>-->
    <!--      <CButton color="danger ml-2">-->
    <!--        Upload-->
    <!--      </CButton>-->
    <!--    </div>-->
    <!--    </CModal>-->

    <Modal
      :modalShow="modal"
      messageGeneral=""
      :title="texts.uploadFile.title"
      @doFunction="handleUpload"
      cancel-btn-label="Cancel"
      success-btn-label="Upload"
      v-on:toggle-modal="closeModal"
    >
      <template #body>
        <CRow class="import-file" @drop.prevent="addFile" @dragover.prevent>
          <CCol class="mb-3 p-0">
            <span class="import-file-subtitle">{{ texts.uploadFile.description }}</span>
          </CCol>
          <CCol class="card">
            <div class="actions-wrapper input-group">
              <div class="actions custom-file">
                <CCol class="browse-field">
                  <div class="import-csv-label">
                    <label class="csv-icon zq--custom-button" for="uploadFieldName">
                      <i class="fa fa-upload"></i>
                      <span class="csv-icon--label">{{ texts.uploadFile.browse }}</span>
                    </label>
                    <input
                      type="file"
                      multiple
                      accept=".csv"
                      ref="csvInput"
                      id="uploadFieldName"
                      name="uploadFieldName"
                      @change="parse($event)"
                      class="input-file"
                    />
                  </div>
                  <label class="csv-name" for="uploadFieldName">
                    <div>{{ textShorten(fileName) }}</div>
                  </label>
                </CCol>
              </div>
            </div>
            <div class="d-flex align-items-center mt-3">
              <ClCheckbox
                :valueProp="replaceFiles"
                :label="texts.uploadFile.replaceLabel"
                :checkedProp="replaceFiles"
                class="mr-2"
                @handler="replaceFilesHandler"
              />
              <IconWithTooltip class="mb-1"/>
            </div>
          </CCol>
        </CRow>
      </template>
    </Modal>
  </div>
</template>

<script>
import Papa from "papaparse";
import Modal from '@/shared/UI/Modal';
import {textShorten} from "@/utils/textUtils";
import ClCheckbox from "@/shared/components/formComponents/ClCheckbox";
import IconWithTooltip from "@/shared/UI/IconWithTooltip";
import {webAssetsTexts} from "@/config/pageTexts/webAssets.json";

export default {
  name: 'uploadFileModal',
  components: {
    Modal,
    IconWithTooltip,
    ClCheckbox
  },
  props: {
    modalShow: Boolean,
  },
  data() {
    return {
      replaceFiles: false,
      fileName: '',
      modal: false,
      texts: {
        ...webAssetsTexts
      },
    }
  },
  watch: {
    modalShow(val) {
      this.modal = val;
      if (!val) {
        this.fileName = ''
      }
    }
  },
  methods: {
    uploadFile() {
      this.modal = true;
      document.querySelector('.custom-file-input').value = null;
    },
    textShorten(text) {
      return textShorten(text, 40);
    },
    addFile(e) {
      const droppedFiles = e.dataTransfer.files;
      this.parseFile(droppedFiles[0]);
    },
    parseFile(file) {
      if (file) {
        this.fileName = file.name;
        const reader = new FileReader();
        reader.onload = e => {
          const file1 = Papa.parse(e.target.result, {
            header: true,
          });
          console.log(file1)
        };
        reader.readAsText(file);
      }
    },
    parse(event) {
      const file = event.target.files[0];
      this.parseFile(file)
    },
    closeModal() {
      this.fileName = '';
      this.$emit('toggle-modal', false)
    },
    handleUpload() {

    },
    replaceFilesHandler({value}) {
      this.replaceFiles = value;
    }
  }
}
</script>

<style lang="scss">
.uploadFile-modal {
  .modal-content {
    background-color: #f3f3f3;
  }
  .modal-header {
    background-color: transparent;
    border: none;
    .modal-title {
      color: black;
    }
    .close:focus {
      border: none;
    }
  }
  .modal-body {
    padding: 0 2rem;
    .csv-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.5rem;
      margin-right: 5px;
      background: transparent;
      border: 1px solid black;
      border-radius: 7px;
      cursor: pointer;
      min-height: 40px;
      span {
        color: black;
        margin-left: .5rem;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
      }
      i {
        color: #282f37;
      }
      &:hover {
        background-color: #636f83;
        border-color: #636f83;
        color: white;
        span {
          color: white;
        }
        i {
          color: white;
        }
      }
    }
    .input-file {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
    .browse-field {
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      width: 95%;
      padding: 0;
      min-height: 30px;
      .csv-name {
        flex: auto;
        margin-right: .5rem;
        height: 40px;
        padding: 10px 24px 8px 16px;
        border-radius: 7px;
        background-color: #ffffff;
        border: 1px solid grey;
        overflow: hidden;
        div {
          overflow: hidden;
          height: 20px;
        }
      }
    }
  }
  .card {
    background-color: white;
    border-radius: 12px;
    border: none;
    padding: 1.5rem;
  }
  .modal-content {
    border: none;
  }
  .import-file {
    background: #f3f3f3;
    display: flex;
    flex-direction: column;
    font-size: 12px;
  }
  .form-check-input {
    margin-top: 0.1rem;
  }
  .form-check-label {
    color: #9c9696;
    font-weight: 500;
  }
  .modal-footer {
    background-color: #fff;
    border-top: none;
  }
  .custom-file-label::after {
    display: none;
    color: #fff;
    background-color: #e55353;
  }
  .browse-txt {
    font-weight: bold;
  }
  .custom-checkbox-select .form-check-input {
    margin-top: -5px;
    margin-left: -16px !important;
    text-align: center;
  }
  #inputGroupFile01 button {
    display: none;
  }
  .custom-file-input {
    opacity: 1;
  }
  input[type="file"] {
    visibility: hidden;
  }
  .import-file-subtitle {
    color: #a4a4a4;
    font-size: 14px;
    font-weight: 600;
  }
}
</style>