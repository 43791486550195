<template>
  <div class="files">
    <!--    HEADER    -->
    <div class="zq-page-title-wrapper d-flex">
      <h3 class="zq-page-title">{{ formData.name }}</h3>
      <IconWithTooltip
        class="zq--header-tooltip"
        :text="descriptions.pageTitle"
      />
    </div>
    <CTabs
      :activeTab="
        (files.length <= 0 && !isSearchVal) ||
        this.$route.path.indexOf('properties') > -1
          ? 1
          : 0
      "
      class="zq--tabs"
    >
      <CTab id="files-tab" :title="texts.previewRepoPage.tabFiles.title">
        <!--:to="`/web-assets/files/${this.$route.params.id}`"-->
        <div
          v-if="files.length > 0 || isSearchVal"
          class="form-content content"
        >
          <CRow>
            <CCol col="12">
              <TableHeader
                :originalFields="originalFields"
                :fields="fields"
                :statuses="statuses"
                :isVisible="selectedFiles.length"
                @updateFields="updateFields"
                @createItem="createNewFolder"
                @toggleFilter="showColumnFilter"
                @checkStatus="checkStatus"
                @searchData="searchFiles"
                @clearFilter="clearFilter"
                @deleteData="deleteFiles"
              ></TableHeader>
            </CCol>
          </CRow>
          <CRow class="mt-0">
            <CCol col="12">
              <CCard class="zq-card-table">
                <CCardBody>
                  <CDataTable
                    id="filesTable"
                    ref="filesTable"
                    class="zq--table"
                    :items="files"
                    :fields="fields"
                    :column-filter="columnFilter"
                    :items-per-page="itemsPerPage"
                    :sorter="{ external: true }"
                    :responsive="true"
                    :border="isDefaultTheme"
                    :hover="isDefaultTheme"
                    :striped="isDefaultTheme"
                    :loading="loading"
                    @pagination-change="itemsPerPageSelect"
                    @page-change="paginationChange"
                    @update:column-filter-value="handleColumnFilter"
                    @update:sorter-value="handleSortBy"
                  >
                    <template #id-filter>
                      <FilterRepos
                        field="id"
                        @handleChangeData="handleColumnFilter"
                      />
                    </template>
                    <template #spaceName-filter>
                      <FilterRepos
                        field="spaceName"
                        @handleChangeData="handleColumnFilter"
                      />
                    </template>
                    <template #created-filter>
                      <FilterRepos
                        field="created"
                        @handleChangeData="handleColumnFilter"
                      />
                    </template>
                    <template #repositoryId-filter>
                      <FilterRepos
                        field="repositoryId"
                        @handleChangeData="handleColumnFilter"
                      />
                    </template>
                    <template #fileName-filter>
                      <FilterRepos
                        field="fileName"
                        @handleChangeData="handleColumnFilter"
                      />
                    </template>
                    <template #mimeType-filter>
                      <FilterRepos
                        field="mimeType"
                        @handleChangeData="handleColumnFilter"
                      />
                    </template>
                    <template #uri-filter>
                      <FilterRepos
                        field="uri"
                        @handleChangeData="handleColumnFilter"
                      />
                    </template>
                    <template #path-filter>
                      <FilterRepos
                        field="path"
                        @handleChangeData="handleColumnFilter"
                      />
                    </template>
                    <template #parentFolderPath-filter>
                      <FilterRepos
                        field="parentFolderPath"
                        @handleChangeData="handleColumnFilter"
                      />
                    </template>
                    <template #metadata-filter>
                      <FilterRepos
                        field="metadata"
                        @handleChangeData="handleColumnFilter"
                      />
                    </template>
                    <template #parentFolderName-filter>
                      <FilterRepos
                        field="parentFolderName"
                        @handleChangeData="handleColumnFilter"
                      />
                    </template>
                    <template #select-header class="text-center">
                      <div
                        class="position-relative zq--table-select-all"
                        v-theme-select-all
                      >
                        <ClCheckbox
                          :checkedProp="toggleFlag"
                          icon="fa-angle-down"
                          @handler="selectAll"
                        />
                      </div>
                    </template>
                    <template #select-filter>
                      <div></div>
                    </template>
                    <template #select="{ item }">
                      <td
                        :data-id="item.id"
                        class="text-center select-td fixed-column-start"
                      >
                        <ClCheckbox
                          :valueProp="item.id"
                          :checkedProp="selectedFiles.indexOf(item.id) > -1"
                          @handler="checkFiles"
                        />
                      </td>
                    </template>
                    <template #id="{ item }">
                      <td @click="showFileOverview(item.id, item.name)">
                        <a class="files-table--link">
                          <CIcon
                            v-if="item.mimeType === 'application/x-directory'"
                            name="cil-folder-open"
                          ></CIcon>
                          <CIcon v-else name="cil-file"></CIcon>
                          <span class="ml-1">{{ item.id }}</span>
                        </a>
                      </td>
                    </template>
                    <template #fileName="{ item }">
                      <td>{{ item.fileName }}</td>
                    </template>
                    <template #created="{ item }">
                      <td>{{ dateFormate(item.created) }}</td>
                    </template>
                    <template #metadata="{ item }">
                      <td>{{ getMetaData(item.metadata) }}</td>
                    </template>
                    <template #actions-header>
                      <div v-theme-header-actions></div>
                    </template>
                    <template #actions-filter>
                      <div></div>
                    </template>
                    <template #actions="{ item }">
                      <td
                        :data-id="item.id"
                        class="members-action text-center fixed-column-end"
                      >
                        <div class="zq--table-actions-wrap">
                          <CDropdown
                            inNav
                            class="c-header-nav-items custom-dropbox"
                            id="custom-dropbox"
                            placement="left-start"
                            add-menu-classes="p-0"
                          >
                            <template #toggler>
                              <CButton class="zq--table-actions--single">
                                <ClIcon
                                  cilIcon="cil-options"
                                  iconTypeProp="cil"
                                  imgIcon="actions-more"
                                />
                              </CButton>
                            </template>
                            <CDropdownItem class="p-0">
                              <CButton
                                v-if="
                                  item.mimeType === 'application/x-directory'
                                "
                                pill
                                color="dark"
                                class="action-custom-button"
                                variant="ghost"
                                @click="editFolder(item)"
                                >{{
                                  texts.previewRepoPage.tabFiles.edit
                                }}</CButton
                              >
                              <CButton
                                v-else
                                pill
                                color="dark"
                                class="action-custom-button"
                                variant="ghost"
                                @click="editFile(item)"
                                >{{
                                  texts.previewRepoPage.tabFiles.edit
                                }}</CButton
                              >
                            </CDropdownItem>
                            <CDropdownItem
                              v-if="item.mimeType === 'application/x-directory'"
                              class="p-0"
                            >
                              <CButton
                                pill
                                color="dark"
                                class="action-custom-button"
                                variant="ghost"
                                @click="cloneFolder(item)"
                                >{{
                                  texts.previewRepoPage.tabFiles.clone
                                }}</CButton
                              >
                            </CDropdownItem>
                            <CDropdownItem v-else class="p-0">
                              <CButton
                                pill
                                color="dark"
                                class="action-custom-button"
                                variant="ghost"
                                @click="cloneFile(item)"
                                >{{
                                  texts.previewRepoPage.tabFiles.clone
                                }}</CButton
                              >
                            </CDropdownItem>
                            <CDropdownItem class="p-0">
                              <CButton
                                pill
                                color="dark"
                                class="action-custom-button"
                                variant="ghost"
                                >{{
                                  texts.previewRepoPage.tabFiles.download
                                }}</CButton
                              >
                            </CDropdownItem>
                            <CDropdownItem
                              v-if="item.mimeType === 'application/x-directory'"
                              class="p-0"
                            >
                              <CButton
                                pill
                                color="dark"
                                class="action-custom-button"
                                variant="ghost"
                                @click="emptyFolder(item)"
                                >{{
                                  texts.previewRepoPage.tabFiles.empty
                                }}</CButton
                              >
                            </CDropdownItem>
                            <CDropdownItem v-else class="p-0">
                              <CButton
                                pill
                                color="dark"
                                class="action-custom-button"
                                variant="ghost"
                                @click="moveFile(item)"
                                >{{
                                  texts.previewRepoPage.tabFiles.move
                                }}</CButton
                              >
                            </CDropdownItem>
                            <CDropdownItem class="p-0">
                              <CButton
                                pill
                                color="dark"
                                class="action-custom-button"
                                variant="ghost"
                                @click="
                                  deleteModal = true;
                                  messageGeneral = messageForSingle;
                                  selectedId = item.id;
                                "
                                >{{
                                  texts.previewRepoPage.tabFiles.delete
                                }}</CButton
                              >
                            </CDropdownItem>
                          </CDropdown>
                        </div>
                      </td>
                    </template>
                  </CDataTable>
                  <TableFooter
                    :page="page"
                    @updatePagenation="paginationChange"
                    :pages="pages"
                    :total="resultCount"
                    :itemsPerPage="itemsPerPage"
                    @updateItemPerPage="itemsPerPageSelect"
                  />
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
          <!--     MODAL     -->
          <Modal
            :modalShow="deleteModal"
            :messageGeneral="messageGeneral"
            :title="deleteFileTitle"
            @doFunction="deleteFile"
            v-on:toggle-modal="closeModal('delete')"
          />
          <Modal
            :modalShow="cloneModalFolder"
            :messageGeneral="messageCloneFolder"
            :title="cloneFolderTitle"
            @doFunction="clone"
            v-on:toggle-modal="closeModal('clone')"
          />
          <Modal
            :modalShow="cloneModalFile"
            :messageGeneral="messageCloneFile"
            :title="cloneFileTitle"
            @doFunction="clone"
            v-on:toggle-modal="closeModal('clone')"
          />
          <Modal
            :modalShow="emptyModal"
            :messageGeneral="emptyMessage"
            :title="emptyTitle"
            @doFunction="empty"
            v-on:toggle-modal="closeModal('empty')"
          />
          <MoveFileModal
            class="moveFileModal"
            :modalShow="moveFileModal"
            v-on:toggle-modal="closeModal('move')"
          />
        </div>
        <!--     NO CONTENT   -->
        <div v-else class="content no-file-content">
          <CRow class="no-files-txt">
            <CCol class="text-center">
              <p>{{ texts.previewRepoPage.tabFiles.noContentMessage }}</p>
              <input
                type="file"
                id="file"
                ref="file"
                v-on:change="submitFile"
                style="display: none"
              />
              <CButton
                pill
                class="zq--custom-button"
                variant="ghost"
                @click="handleFileUpload"
                >{{ texts.previewRepoPage.tabFiles.noContentBtn }}</CButton
              >
              <!--              <CDropdown-->
              <!--                inNav class="c-header-nav-items action-button-groups mr-2 no-file-drop"-->
              <!--                placement="bottom-end"-->
              <!--                add-menu-classes="p-0"-->
              <!--              >-->
              <!--                <template #toggler>-->
              <!--                  <CButton-->
              <!--                    variant="outline"-->
              <!--                    :color="colorBtn"-->
              <!--                    class="zq--custom-button"-->
              <!--                  >-->
              <!--                    Select Action-->
              <!--                    <i v-if="theme === 'main'" class="fa fa-angle-down font-weight-bold"></i>-->
              <!--                    <i v-else class="fa fa-caret-down"></i>-->
              <!--                  </CButton>-->
              <!--                </template>-->
              <!--                <CDropdownItem class="p-0">-->
              <!--                  <CButton-->
              <!--                    pill-->
              <!--                    :color="colorBtn"-->
              <!--                    class="action-custom-button"-->
              <!--                    variant="ghost"-->
              <!--                    @click="createNewFolder"-->
              <!--                  >-->
              <!--                    CreateFolder-->
              <!--                  </CButton>-->
              <!--                </CDropdownItem>-->
              <!--                <CDropdownItem class="p-0">-->
              <!--                  <CButton-->
              <!--                    pill-->
              <!--                    :color="colorBtn"-->
              <!--                    class="action-custom-button"-->
              <!--                    variant="ghost"-->
              <!--                    @click="uploadFile"-->
              <!--                  >-->
              <!--                    Upload File-->
              <!--                  </CButton>-->
              <!--                </CDropdownItem>-->
              <!--              </CDropdown>-->
            </CCol>
          </CRow>
        </div>
      </CTab>
      <CTab
        id="preview-repo"
        data-tab="prop"
        :title="texts.previewRepoPage.tabProperties.title"
      >
        <div class="preview-container content">
          <CRow class="m-0 mb-4">
            <CCol
              col="12"
              class="d-flex justify-content-md-end align-items-center"
            >
              <CDropdown
                class="c-header-nav-items dropdown mr-2 action-button-groups"
                color="dark"
                variant="outline"
                placement="bottom-end"
                title="Repository Actions"
                button-content="Dropdown"
              >
                <template #toggler>
                  <CButton
                    variant="outline"
                    :color="colorBtn"
                    class="zq--custom-button"
                  >
                    <span class="mr-1">{{
                      texts.previewRepoPage.tabProperties.actions
                    }}</span>
                    <i
                      v-if="theme === 'main'"
                      class="fa fa-angle-down font-weight-bold"
                    ></i>
                    <i v-else class="fa fa-caret-down"></i>
                  </CButton>
                </template>
                <CDropdownItem class="p-0">
                  <CButton
                    pill
                    :color="colorBtn"
                    class="action-custom-button"
                    variant="ghost"
                    @click="editRepository"
                    >{{ texts.previewRepoPage.tabProperties.edit }}</CButton
                  >
                </CDropdownItem>
                <CDropdownItem class="p-0">
                  <CButton
                    pill
                    :color="colorBtn"
                    class="action-custom-button"
                    variant="ghost"
                    @click="deleteModal = true"
                    >{{ texts.previewRepoPage.tabProperties.delete }}</CButton
                  >
                </CDropdownItem>
              </CDropdown>
              <ClResponsiveButton
                :label="$t('buttons.createFolder')"
                @handler="createNewFolder"
              />
            </CCol>
          </CRow>
          <div class="form-content" style="border-top: none">
            <CRow>
              <CCol col="12">
                <CCard class="zq--wizard-card">
                  <CCardHeader @click="basicIsCollapsed = !basicIsCollapsed">
                    <div class="d-flex justify-content-between">
                      <strong class="title">{{
                        texts.previewRepoPage.tabProperties.overview
                      }}</strong>
                      <CLink class="card-header-action btn-minimize">
                        <ClCardArrow :is-collapsed="basicIsCollapsed" />
                      </CLink>
                    </div>
                  </CCardHeader>
                  <CCollapse :show="basicIsCollapsed" :duration="400">
                    <CCardBody>
                      <!--          ID            -->
                      <ClCardRow
                        :label="texts.previewRepoPage.tabProperties.idLabel"
                        :content="formData.id"
                      />
                      <!--        Created              -->
                      <ClCardRow
                        :label="
                          texts.previewRepoPage.tabProperties.createdLabel
                        "
                        :content="dateFormate(formData.created)"
                      />
                      <ClCardRow
                        label="Space Name"
                        :content="formData.spaceName"
                      />
                      <!--          NAME            -->
                      <ClCardRow
                        :label="texts.previewRepoPage.tabProperties.nameLabel"
                        :content="formData.name"
                      />
                      <!--          Description            -->
                      <ClCardRow
                        :label="
                          texts.previewRepoPage.tabProperties.descriptionLabel
                        "
                        :content="formData.description"
                      />
                      <ClCardRow
                        label="Index File Name"
                        :content="
                          formData.hostingOptions
                            ? formData.hostingOptions.indexFileName
                            : ''
                        "
                      />
                      <ClCardRow
                        label="Error File Name"
                        :content="
                          formData.hostingOptions
                            ? formData.hostingOptions.indexerrorFileNameFileName
                            : ''
                        "
                      />
                      <CICustomFieldsView
                        :customFields="formData.customFields"
                      />
                      <!--           Tags            -->
                      <ClCardRow
                        :label="texts.previewRepoPage.tabProperties.tagsLabel"
                      >
                        <template #content>
                          <ul class="zq--list">
                            <li
                              v-for="(group, index) in formData.tags"
                              :key="index"
                              class="group__colored mr-2"
                            >
                              {{ group }}
                            </li>
                          </ul>
                        </template>
                      </ClCardRow>
                      <!--           Metadata           -->
                      <ClCardRow
                        :label="
                          texts.previewRepoPage.tabProperties.metadataLabel
                        "
                      >
                        <template #content>
                          <CIMetaDataView :metadata="formData.metadata" />
                        </template>
                      </ClCardRow>
                    </CCardBody>
                  </CCollapse>
                </CCard>
              </CCol>
            </CRow>
            <CRow>
              <CCol col="12">
                <CCard class="zq--wizard-card">
                  <CCardHeader @click="filesIsCollapsed = !filesIsCollapsed">
                    <div class="d-flex justify-content-between">
                      <strong class="title">{{
                        texts.previewRepoPage.tabProperties.filesFoldersOverview
                      }}</strong>
                      <CLink class="card-header-action btn-minimize">
                        <ClCardArrow :is-collapsed="filesIsCollapsed" />
                      </CLink>
                    </div>
                  </CCardHeader>
                  <CCollapse :show="filesIsCollapsed" :duration="400">
                    <CCardBody>
                      <CRow></CRow>
                    </CCardBody>
                  </CCollapse>
                </CCard>
              </CCol>
            </CRow>
            <CRow>
              <CCol col="12">
                <CCard class="zq--wizard-card">
                  <CCardHeader @click="otherIsCollapsed = !otherIsCollapsed">
                    <div class="d-flex justify-content-between">
                      <strong class="title">{{
                        texts.previewRepoPage.tabProperties.other
                      }}</strong>
                      <CLink class="card-header-action btn-minimize">
                        <ClCardArrow :is-collapsed="otherIsCollapsed" />
                      </CLink>
                    </div>
                  </CCardHeader>
                  <CCollapse :show="otherIsCollapsed" :duration="400">
                    <CCardBody>
                      <CRow></CRow>
                    </CCardBody>
                  </CCollapse>
                </CCard>
              </CCol>
            </CRow>
          </div>
          <Modal
            :modalShow="deleteModal"
            :messageGeneral="delRepoMsg"
            :title="deleteRepoTitle"
            @doFunction="deleteRepository"
            v-on:toggle-modal="closeModal('delete')"
          />
        </div>
      </CTab>
    </CTabs>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import TableFooter from '@/components/table/Footer';
import TableHeader from './webAssetsHeader';
import * as _ from 'lodash';
import FilterRepos from '@/components/table/ColumnFilter';
import Modal from '@/shared/UI/Modal';
import MoveFileModal from './MoveFileModal';
import ClCheckbox from '@/shared/components/formComponents/ClCheckbox';
import ClResponsiveButton from '@/shared/components/formComponents/ClResponsiveButton';
import routerBreadcrumbs from '@/router/breadcrumb/routerBreadcrumbs';
import ClCardRow from '@/shared/UI/ClCardRow';
import ClCardArrow from '@/shared/UI/ClCardArrow';
import CIMetaDataView from '@/shared/components/CIMetaDataView';
import ClIcon from '@/shared/UI/ClIcon';
import IconWithTooltip from '@/shared/UI/IconWithTooltip';
import { webAssets } from '@/config/descriptions/webAssets';
import { dateFormate } from '@/utils/dateFormate';
import { webAssetsTexts } from '@/config/pageTexts/webAssets.json';
import CICustomFieldsView from '@/shared/components/CICustomFieldsView';

export default {
  name: 'PreviewRepository',
  components: {
    TableFooter,
    TableHeader,
    FilterRepos,
    Modal,
    MoveFileModal,
    ClResponsiveButton,
    ClCheckbox,
    ClCardRow,
    ClCardArrow,
    ClIcon,
    IconWithTooltip,
    CIMetaDataView,
  },
  data() {
    return {
      descriptions: {
        ...webAssets.repos.preview,
      },
      texts: {
        ...webAssetsTexts,
      },
      formData: {
        name: '',
        tags: [],
        metadata: [{ key: '', value: '' }],
        id: '',
        description: '',
      },
      editorOption: {
        theme: 'snow',
      },
      options: [],
      basicIsCollapsed: true,
      filesIsCollapsed: false,
      otherIsCollapsed: false,
      //Files Page
      page: 1,
      deleteMsg: webAssetsTexts.previewRepoPage.deleteRepoSuccess,
      itemsPerPage: 10,
      query: {},
      sortBy: [
        {
          queryField: 'created',
          order: 'Desc',
        },
      ],
      columnFilter: false,
      actions: 2,
      statuses: [],
      deleteModal: false,
      messageGeneral: webAssetsTexts.previewRepoPage.messageGeneral,
      messageForSingle: webAssetsTexts.previewRepoPage.messageForSingle,
      selectedId: null,
      selectedFiles: [],
      clickable: true,
      entries: 0,
      toggleFlag: false,
      repoId: '',
      repoName: '',
      fileId: '',
      currentPage: '',
      isFilesActive: true,
      isPropActive: false,
      showCMetaInputs: false,
      activeTab: 0,
      messageCloneFolder: webAssetsTexts.previewRepoPage.messageCloneFolder,
      messageCloneFile: webAssetsTexts.previewRepoPage.messageCloneFile,
      cloneModalFolder: false,
      cloneModalFile: false,
      clonedFolder: {},
      isProperties: false,
      isSearchVal: false,
      uploadModal: false,
      deleteFileTitle: webAssetsTexts.previewRepoPage.deleteFileTitle,
      delRepoMsg: webAssetsTexts.previewRepoPage.tabProperties.deleteMessage,
      deleteRepoTitle: webAssetsTexts.previewRepoPage.tabProperties.deleteTitle,
      cloneFolderTitle: webAssetsTexts.previewRepoPage.cloneFolderTitle,
      cloneFileTitle: webAssetsTexts.previewRepoPage.cloneFileTitle,
      emptyMessage: webAssetsTexts.previewRepoPage.emptyMessage,
      emptyTitle: webAssetsTexts.previewRepoPage.emptyTitle,
      emptyModal: false,
      moveFileModal: false,
      file: '',
    };
  },
  created() {
    this.handleGetRepoById(this.$route.params.id).then((data) => {
      routerBreadcrumbs(this.$router.currentRoute, {
        name: data[0].name,
      });
    });
    this.repoId = this.$route.params.id;
  },
  mounted() {
    //Get Files by providing repository Id
    this.query = { repositoryId: this.repoId };
    this.handleGetFilesByRepoId({
      queryData: this.query,
      sortBy: this.sortBy,
      skip: (this.page - 1) * this.itemsPerPage,
      limit: this.itemsPerPage,
    });
    this.handleFileFields();
  },
  computed: {
    ...mapGetters('repos', ['success', 'message', 'repos', 'repo']),
    ...mapGetters('theme', ['theme']),
    ...mapGetters('files', [
      'files',
      'fields',
      'pages',
      'loading',
      'resultCount',
      'originalFields',
      'success',
      'message',
    ]),
    ...mapGetters('theme', ['theme']),
    isDefaultTheme() {
      return this.theme === 'default';
    },
    colorBtn() {
      switch (this.theme) {
        case 'main': {
          return '';
        }
        case 'default': {
          return 'dark';
        }
        default: {
          return '';
        }
      }
    },
  },
  methods: {
    ...mapActions('repos', [
      'handleDeleteRepository',
      'handleEditRepository',
      'handleGetRepoById',
    ]),
    ...mapActions('files', [
      'handleGetFilesByRepoId',
      'handleFileFields',
      'handleDeleteFile',
      'handleGetFileById',
      'handleUploadFileObject',
      'handleUpdateFile',
      'handleCloneFile',
      'handleClearFilter',
    ]),
    dateFormate,
    handleFileUpload() {
      this.$refs.file.click();
    },
    submitFile() {
      this.file = this.$refs.file.files[0];
      if (this.file) {
        let body = {
          files: this.file,
          parentFolderPath: '/parent/folder/path',
          repositoryId: this.$route.params.id,
        };
        this.handleUploadFileObject(body);
      }
    },
    getMetaData(items) {
      let meta = '';
      const maxlength = 20;
      if (items && items.length > 0) {
        items.forEach((element) => {
          meta += element.key + ': ' + element.value + '\n';
        });
      }
      return meta.length > maxlength
        ? meta.substring(0, maxlength - 3) + '...'
        : meta.substring(0, maxlength);
    },
    deleteRepository() {
      this.deleteModal = false;
      this.handleDeleteRepository({
        id: [this.$route.params.id],
        queryData: this.query,
        sortBy: this.sortBy,
        skip: (this.page - 1) * this.itemsPerPage,
        limit: this.itemsPerPage,
      });
    },

    //Single File/Folder Methods
    updateFields(val) {
      this.handleFileFields(val);
    },
    createNewFolder() {
      this.$router.push({
        name: 'CreateFolder',
      });
    },
    upload() {
      //API yet to be implemented
    },
    //Repository Actions
    editRepository() {
      this.$router.push({
        name: 'EditRepository',
        params: {
          id: this.$route.params.id,
        },
      });
    },
    editFile(val) {
      var repo = this.repoId;
      var name = this.repoName;
      this.$router.push({
        path: `/cms/preview-repository/${repo}/${name}/update-file/${val.id}/${val.fileName}`,
      });
    },
    renameFile() {
      //this.$router.push('/web-assets/create-file');
    },
    moveFile(val) {
      console.log('move file', val);
      this.moveFileModal = true;
    },
    emptyFolder(val) {
      this.emptyModal = true;
      this.fileId = val.id;
    },
    empty() {
      console.log(this.fileId);
      //To-Do
    },
    closeModal(val) {
      if (val === 'delete') {
        this.deleteModal = false;
      } else if (val === 'empty') {
        this.emptyModal = false;
      } else if (val === 'clone') {
        this.cloneModalFolder = false;
        this.cloneModalFile = false;
      } else if (val === 'move') {
        this.moveFileModal = false;
      }
    },
    showFileOverview(fileId, fileName) {
      const repo = this.repoId;
      const repoName = this.repoName;
      this.$router.push({
        path: `/cms/preview-repository/${repo}/${repoName}/file-overview/${fileId}/${fileName}`,
      });
    },
    deleteFile() {
      this.deleteModal = false;
      this.query.repositoryId = this.repoId;
      this.handleDeleteFile({
        id: [this.selectedId],
        queryData: this.query,
        sortBy: this.sortBy,
        skip: (this.page - 1) * this.itemsPerPage,
        limit: this.itemsPerPage,
      });
      this.selectedFiles = [];
      this.toggleFlag = false;
    },
    editFolder(val) {
      const repo = this.repoId;
      const name = this.repoName;
      this.$router.push({
        path: `/cms/preview-repository/${repo}/${name}/update-file/${val.id}/${val.fileName}`,
      });
    },
    cloneFolder(val) {
      this.cloneModalFolder = true;
      this.clonedFolder = _.cloneDeep(val);
    },
    cloneFile(val) {
      this.cloneModalFile = true;
      this.clonedFile = _.cloneDeep(val);
    },
    clone() {
      const folderName = this.clonedFolder.fileName + '-COPY';
      const body = [
        {
          repositoryId: this.$route.params.id,
          fileName: folderName,
          tags: this.clonedFolder.tags,
          metadata: this.clonedFolder.metadata,
          parentFolderPath: this.clonedFolder.parentFolderPath,
          mimeType: 'application/x-directory',
        },
      ];
      this.handleCloneFile(body);
      this.cloneModalFolder = false;
      this.cloneModalFile = false;
    },

    //Multiple Files/Folders methods

    deleteFiles() {
      this.messageGeneral =
        'You are about to delete ' +
        this.selectedFiles.length +
        ' files , Are you sure?';
      this.deleteModal = true;
      const selectedFiles = _.cloneDeep(this.selectedFiles);
      this.selectedId = selectedFiles.toString();
    },

    searchFiles(val) {
      this.isSearchVal = true;
      this.query = { fileName: val };
      if (val === '') {
        this.query = { repositoryId: this.repoId };
      }
      this.handleGetFiles({
        queryData: this.query,
        sortBy: this.sortBy,
        skip: (this.page - 1) * this.itemsPerPage,
        limit: this.itemsPerPage,
      });
    },
    checkFiles({ event }) {
      const index = this.selectedFiles.indexOf(event.target.value);
      if (index > -1) {
        this.selectedFiles.splice(index, 1);
      } else {
        this.selectedFiles.push(event.target.value);
      }
    },
    selectAll() {
      const files = _.cloneDeep(this.files);
      this.toggleFlag = !this.toggleFlag;
      if (this.toggleFlag)
        files.forEach((item) => this.selectedFiles.push(item.id));
      else this.selectedFiles = [];
    },

    //Filters and Pagination methods
    showColumnFilter(val) {
      this.columnFilter = val;
      if (!val) {
        this.formatFilter();
      }
    },
    checkStatus(val) {
      this.status = val;
    },
    formatFilter() {
      this.$refs.filesTable.clean();
      this.page = 1;
      this.query = { repositoryId: this.repoId };
      this.sortBy = [
        {
          queryField: 'created',
          order: 'Desc',
        },
      ];
      this.handleGetFiles({
        queryData: this.query,
        sortBy: this.sortBy,
        skip: (this.page - 1) * this.itemsPerPage,
        limit: this.itemsPerPage,
      });
    },
    handleSortBy(e) {
      const sort = [
        {
          queryField: e.column,
          order: e.asc ? 'ASC' : 'Desc',
        },
      ];
      this.sortBy = sort;
      this.page = 1;
      this.handleGetFiles({
        queryData: this.query,
        sortBy: this.sortBy,
        skip: (this.page - 1) * this.itemsPerPage,
        limit: this.itemsPerPage,
      });
    },
    handleColumnFilter(e) {
      if (e.field && e.value) {
        this.query[e.field] = e.value.trim();
      } else {
        delete this.query[e.field];
      }
      this.handleGetFiles({
        queryData: this.query,
        sortBy: this.sortBy,
        skip: (this.page - 1) * this.itemsPerPage,
        limit: this.itemsPerPage,
      });
    },
    clearFilter() {
      this.handleClearFilter();
    },
    itemsPerPageSelect(val) {
      this.itemsPerPage = val;
      this.page = 1;
      this.handleGetFiles({
        queryData: this.query,
        sortBy: this.sortBy,
        skip: (this.page - 1) * this.itemsPerPage,
        limit: this.itemsPerPage,
      });
    },
    paginationChange(val) {
      this.page = val;
      this.handleGetFiles({
        queryData: this.query,
        sortBy: this.sortBy,
        skip: (this.page - 1) * this.itemsPerPage,
        limit: this.itemsPerPage,
      });
    },
  },

  watch: {
    repo: {
      deep: true,
      handler: function (val) {
        this.formData = _.cloneDeep(val);
        this.repoName = this.formData.name;
        this.showCMetaInputs = true;
      },
    },
    repos: {
      deep: true,
      handler: function () {
        setTimeout(() => {
          this.$router.push({ path: '/cms' });
        }, 1000);
      },
    },
  },
};
</script>

<style lang="scss">
.files {
  height: 100%;
  .content {
    background: var(--zq-sub-bg);
  }
  .form-content {
    height: calc(100% - 50px);
    background: var(--zq-sub-bg);
    border-top: 1px solid var(--zq-content-border-color);
    .members-action .c-header-nav-items {
      list-style: none;
    }
  }
  #filesTable {
    .files-table--link {
      color: #0080a2;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        color: grey;
      }
    }
    .position-relative.table-responsive {
      min-height: 350px;
    }
  }
  #filesTable td > a {
    cursor: pointer;
  }
  #filesTable td > a:hover {
    text-decoration: underline;
    color: #0080a2;
  }
  .no-file-content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15%;
  }
  .no-files-txt {
    margin: 0;
    background: var(--zq-sub-bg);
  }
  .no-file-drop {
    position: absolute;
    margin-top: 5%;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }
  .detail-label {
    @media (min-width: 420px) {
      max-width: 220px;
    }
  }
  .overview-label {
    font-style: italic;
    color: darkgray;
  }
  .action-button-groups {
    list-style: none !important;
    .dropdown-menu {
      min-width: 92px;
    }
  }
}
</style>
