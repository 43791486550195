<template>
  <section v-if="customFields">
    <ClCardRow
      :label="formatedLabel(cf)"
      v-for="(cf, index) in Object.keys(customFields)"
      :key="index"
    >
      <template #content>
        {{ customFields[cf] }}
      </template>
    </ClCardRow>
  </section>
</template>

<script>
export default {
  props: {
    customFields: Object,
  },
  methods: {
    formatedLabel(cf) {
      return cf
        ? cf.split('-').length > 1
          ? cf.split('-').join(' ')
          : cf
        : '';
    },
  },
};
</script>
