<template>
  <div class="moveFile-modal p-1">
    <CModal
      :title="texts.moveFile.title"
      centered
      :show.sync="modal"
      :fade="false"
      addContentClasses="modal-wrap-content"
    >
      <CRow class="import-file" @drop.prevent="moveFile" @dragover.prevent>
        <CCol class="mb-3">
          <span class="import-file-subtitle">{{ texts.moveFile.subtitle }}</span>
        </CCol>
        <CCol class="card">
          <div class="actions-wrapper input-group">
            <div class="actions custom-file">
              <CCol class="browse-field">
                <input
                  type="file"
                  multiple
                  id="moveFieldName"
                  name="moveFieldName"
                  @change="parse($event)"
                  class="custom-file-input"
                />
                <label class="custom-file-label" for="moveFieldName"><p class="file-name">{{ fileName }}</p></label>
              </CCol>
            </div>
          </div>
          <div class="mt-3 ml-1">
            <CInputCheckbox id="replace-files" class=""></CInputCheckbox>
            <label class="form-check-label ml-3" for="replace-files">{{ texts.moveFile.moveAs }}</label>
          </div>
        </CCol>
      </CRow>
      <div slot="footer">
        <CButton  @click="closeModal" color="secondary ml-2">
          {{ texts.moveFile.cancelBtn }}
        </CButton>
        <CButton color="danger ml-2">
          {{ texts.moveFile.moveBtn }}
        </CButton>
      </div>
    </CModal>
  </div>
</template>
<script>
import Papa from "papaparse";
import {webAssetsTexts} from "@/config/pageTexts/webAssets.json";

export default{
  name: 'moveFileModal',
  props: {
    modalShow: Boolean,
  },
  data() {
    return {
      fileName: '',
      modal: false,
      texts: {
        ...webAssetsTexts
      },
    }
  },
  watch: {
    modalShow(val) {
      this.modal = val;
      if (!val) {
        this.fileName = ''
      }
    }
  },
  methods:{
    moveFile(e) {
      const droppedFiles = e.dataTransfer.files;
      this.parseFile(droppedFiles[0]);
    },
    parseFile(file) {
      if (file) {
        this.fileName = file.name;
        const reader = new FileReader();
        reader.onload = e => {
          const file1 = Papa.parse(e.target.result, {
            header: true,
          });
          console.log(file1)
        };
        reader.readAsText(file);
      }
    },
    parse(event) {
      const file = event.target.files[0];
      this.parseFile(file)
    },
    closeModal() {
      this.fileName = '';
      this.$emit('toggle-modal', false)
    },
  }
}
</script>

<style lang="scss">
.moveFile-modal{
  .modal-content{
    background-color:#f3f3f3;;
  }
  .modal-header{
    padding: 1rem 1rem 0.2rem 2rem;
    border: none;
    .close:focus{
      border: none;
    }
  }
  .modal-body{
    padding: 0 2rem;
  }
  .card{
    background-color: white;
    border-radius: 12px;
    border:none;
    padding: 2.5rem;
  }
  .modal-content{
    border:none;
  }
  .import-file {
    background: #f3f3f3;
    display: flex;
    flex-direction: column;
    font-size: 12px;
  }
  .form-check-input{
    margin-top: 0.1rem;
  }
  .form-check-label{
    color: #9c9696;
    font-weight: 500;
  }
  .modal-footer{
    background-color: #fff;
    border-top:none;
  }
  .custom-file-label::after{
    color: #fff;
    background-color: #e55353;
  }
  .browse-txt{
    font-weight: bold;
  }
  .custom-checkbox-select .form-check-input {
    margin-top: -5px;
    margin-left: -16px !important;
    text-align: center;
  }
  #inputGroupFile01 button{
    display: none;
  }
  .custom-file-input{
    opacity: 1;
  }
  input[type="file"] {
    visibility: hidden;
  }
  .import-file-subtitle {
    color: #a4a4a4;
    font-size: 14px;
    font-weight: 600;
  }
}
</style>