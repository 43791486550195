<template>
  <CRow class="zq-webassets-header">
    <CCol col="12" md="6" class="d-flex align-items-center">
      <CSelect
        label
        :options="statuses"
        size="sm"
        class="text-right m-0 d-xl-none text-capitalize"
        :horizontal="{
          label: 'col-1',
          input: 'col-12',
        }"
        v-if="statuses.length > 0"
      />
      <CButton
        variant="outline"
        :color="colorBtn"
        class="mr-2 text-capitalize d-xl-flex d-none ml-3"
        @click="checkStatus('all')"
        v-for="status in statuses"
        :key="status"
      >
        {{ status }}
      </CButton>
      <!--  SEARCH     -->
      <!--  tableHeader.search - from locales    -->
      <ClSearch placeholder="tableHeader.search" @searchUpdate="searchData"/>

      <!-- <CInput
           label
           v-model="search"
           placeholder="Search..."
           class="m-0 search-select"
           @keyup="searchData"
       ></CInput>
       <CIcon
           name="cil-x"
           @click.native="search = null"
           class="cil-x-icon position-absolute"
       ></CIcon>-->

    </CCol>
    <CCol
      col="12"
      md="6"
      class="zq--webassets--actions"
    >
    <ClFilter :isFlag="isFlag" @toggleFilter="toggleFilter"/>
      <CogFields
        :originalFields="originalFields"
        :fields="fields"
        @updateFields="toggleColumn"
        @clearFilter="clearFilter"
      />
      <CDropdown
        inNav
        class="c-header-nav-items action-button-groups mr-2"
        placement="bottom-end"
        add-menu-classes="p-0"
        v-if="isVisible"
      >
        <template v-if="(currentPage==='FileRepositorys')" #toggler>
          <CButton
            variant="outline"
            :color="colorBtn"
            class="zq--custom-button"
          >
            {{ texts.header.actions }}
            <i v-if="theme === 'main'" class="fa fa-angle-down font-weight-bold"></i>
            <i v-else class="fa fa-caret-down"></i>
          </CButton>
        </template>
        <template v-else-if="(currentPage==='Repository')||((currentPage==='PreviewRepository'))" #toggler>
          <CButton
            variant="outline"
            :color="colorBtn"
            class="zq--custom-button"
          >
            {{ texts.header.file }}
            <i v-if="theme === 'main'" class="fa fa-angle-down font-weight-bold"></i>
            <i v-else class="fa fa-caret-down"></i>
          </CButton>
        </template>
        <CDropdownItem @click="deleteData" class="p-0">
          <CButton
            pill
            :color="colorBtn"
            class="action-custom-button btn-upload-file"
            variant="ghost"
            @click="deleteData"
          >
            {{ texts.header.delete }}
          </CButton>
        </CDropdownItem>
        <CDropdownItem v-if="((currentPage==='Repository')|| (currentPage==='PreviewRepository'))" class="p-0">
          <CButton
            pill
            :color="colorBtn"
            class="action-custom-button"
            variant="ghost"
          >
            {{ texts.header.download }}
          </CButton>
        </CDropdownItem>
        <CDropdownItem v-if="((currentPage==='Repository')|| (currentPage==='PreviewRepository'))" class="p-0">
          <CButton
            pill
            :color="colorBtn"
            class="action-custom-button"
            variant="ghost"
            @click="toggleModal(modal = !modal)"
          >
            {{ texts.header.upload }}
          </CButton>
        </CDropdownItem>
        <CDropdownItem v-if="((currentPage==='Repository')|| (currentPage==='PreviewRepository'))" class="p-0">
          <CButton
            pill
            :color="colorBtn"
            class="action-custom-button upload-file"
            variant="ghost"
            @click="moveFile"
          >
            {{ texts.header.move }}
          </CButton>
        </CDropdownItem>
      </CDropdown>
      <ClResponsiveButton v-if="(currentPage==='FileRepositorys')" :label="$t('buttons.create')" @handler="createItem"/>
      <ClResponsiveButton
        v-if="((currentPage==='Repository')|| (currentPage==='PreviewRepository'))"
        :label="$t('buttons.createFolder')"
        @handler="createItem"
      />
      <input type='file' id="file" ref="file" v-on:change="submitFile" style="display:none">
      <ClResponsiveButton
        v-if="((currentPage==='Repository')|| (currentPage==='PreviewRepository'))"
        :label="$t('buttons.uploadFile')"
        @handler="handleFileUpload"
        classes="btn-upload-file"
      />
      <!--<CDropdown v-else-if="currentPage=='Files' || currentPage=='PreviewRepository'" class="c-header-nav-items dropdown mr-2"
                 color="success"  placement="bottom-end" title="Create"  button-content="Dropdown">
        <template #toggler-content> <CIcon name="cil-plus" class="mr-1" />Create</template>
        <CDropdownItem @click="createFolder">Folder</CDropdownItem>-->
      <!-- <CDropdownDivider/>
       <CDropdownItem @click="createFile">File</CDropdownItem></CDropdown>
-->
    </CCol>
    <UploadFileModal
      class="uploadModal"
      id="uploadModal"
      :modalShow="modal"
      v-on:toggle-modal="closeModal"
    />
  </CRow>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {startCase} from "lodash";
import UploadFileModal from './UploadFileModal';
import ClResponsiveButton from '@/shared/components/formComponents/ClResponsiveButton';
import ClSearch from '@/shared/components/formComponents/ClSearch';
import ClFilter from "@/components/header/ClFilter";
import CogFields from "@/components/header/CogFields";
import {webAssetsTexts} from "@/config/pageTexts/webAssets.json";

export default {
  name: 'webAssetsHeader',
  components: {
    UploadFileModal,
    ClResponsiveButton,
    ClSearch,
    ClFilter,
    CogFields
  },
  props: {
    originalFields: Array,
    fields: Array,
    statuses: Array,
    title: String,
    isVisible: {
      type: [Boolean, Number, String],
      default: false
    }
  },
  created() {
    this.currentPage = this.$router.currentRoute.name;
  },
  data() {
    return {
      options: ['Enabled', 'Disabled'],
      isFlag: false,
      search: '',
      currentPage: '',
      modal: false,
      texts: {
        ...webAssetsTexts
      },
    };
  },
  computed: {
    ...mapGetters('theme', ['theme']),
    colorBtn() {
      switch (this.theme) {
        case 'main': {
          return ''
        }
        case 'default' : {
          return 'dark'
        }
        default: {
          return ''
        }
      }
    },
  },
  methods: {
    ...mapActions('files', ['handleUploadFileObject']),
    handleFileUpload() {
      this.$refs.file.click();
    },
    submitFile() {
      this.file = this.$refs.file.files[0];
      if (this.file) {
        let body = {
          files: this.file,
          parentFolderPath: '/parent/folder/path',
          repositoryId: this.$route.params.id,
        }
        this.handleUploadFileObject(body);
      }
    },
    getBinaryString(file) {
      return new Promise(function (resolve, reject) {
        let reader = new FileReader();
        let imgResult = "";
        reader.readAsText(file);
        reader.onload = function () {
          imgResult = reader.result;
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.onloadend = function () {
          resolve(imgResult);
        };
      });
    },
    toggleColumn(value) {
      this.$emit('updateFields', value);
    },
    createItem() {
      this.$emit('createItem');
    },
    toggleFilter() {
      this.isFlag = !this.isFlag;
      this.$emit('toggleFilter', this.isFlag);
    },
    checkStatus(val) {
      this.$emit('checkStatus', val);
    },
    clearFilter() {
      this.$emit('clearFilter');
    },
    deleteData() {
      this.$emit('deleteData');
    },
    searchData(val) {
      this.$emit('searchData', val);
    },
    createFile() {
      this.$emit('createFile');
    },
    createFolder() {
      this.$emit('createFolder');
    },

    caseTransform(val) {
      return startCase(val)
    },
    resetSearch() {
      this.search = '';
      this.$emit('searchData', this.search);
    },
    uploadFile() {
      this.modal = true;
    },
    moveFile() {
      this.$emit('moveFile');
    },
    closeModal() {
      this.modal = false
    },
    toggleModal(val) {
      this.modal = val
    },
  }
};
</script>
<style lang="scss">
@import '~@/assets/scss/mixins.scss';

.zq-webassets-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.zq--webassets--actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  //d-flex justify-content-md-end align-items-center

  @include media-breakpoint-down('576px') {
    flex-wrap: wrap;
  }
}

.button-group {
  position: relative;
  .upload-file {
    height: 100%;
  }
  .btn-upload-file {
    position: absolute;
    top: 0;
    opacity: 0;
  }
}
.search-container {
  position: relative;
  width: 100%;
  .search-clear {
    position: absolute;
    top: 6px;
    right: 2%;
    cursor: pointer;
  }
}
.search-select {
  width: 100%;
}

.btn-upload-file {
  margin-left: 12px;
}

.btn-clear {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.action-button-groups {
  list-style: none !important;

  .dropdown-menu {
    min-width: 92px;
  }

}

.header-icon-buttons {
  border: 1px solid black;
  border-radius: 7px;
  outline: none;
  color: black;
}

.header-icon-buttons:focus {
  box-shadow: none;
}

.action-custom-button {
  width: 100%;
}
</style>
